import React from 'react'
import Layout from '../components/layout'
import SEO from "../components/SEO"

const Error = () => {
  return (
    <div>
      <Layout>
        <SEO title="Not found" description="The page is not found" />
        <p>&nbsp;</p>
        <h2>Oops! The page is not found.</h2>
      </Layout>
    </div>
  )
}

export default Error
